import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useNavigate,
} from 'react-router';

import stylesheet from '@/tailwind.css?url';
import tldrawStylesheet from '@/tldraw.css?url';
import { App as CapacitorApp, URLOpenListenerEvent } from '@capacitor/app';
import { Suspense, useEffect } from 'react';
import { LinksFunction } from 'react-router';
import { ConstructableLogo } from './components/constructable-logo';
import { DefaultErrorBoundary } from './components/default-error-boundary';

export const links: LinksFunction = () => [
  { rel: 'stylesheet', href: stylesheet },
  { rel: 'stylesheet', href: tldrawStylesheet },
  {
    rel: 'stylesheet',
    href: 'https://fonts.googleapis.com/css2?family=Inter:wght@500;700&display=swap',
  },
  { rel: 'icon', href: '/logos/logo.ico', sizes: 'any' },
  { rel: 'icon', href: '/logos/logo.svg', type: 'image/svg+xml' },
  { rel: 'apple-touch-icon', href: '/logos/logo-512.png', sizes: '512x512' },
];

export function Layout({ children }: { children: React.ReactNode }) {
  return (
    <html lang="en">
      <head>
        <meta charSet="utf-8" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1, viewport-fit=cover"
        ></meta>
        <meta name="apple-itunes-app" content="app-id=6651818158"></meta>
        <Meta />
        <Links />
      </head>
      <body className="h-[100dvh] w-[100dwh] overflow-hidden">
        {children}
        <ScrollRestoration />
        <Scripts />
      </body>
    </html>
  );
}

export const HydrateFallback = () => {
  return (
    <div
      style={{
        height: '100vh',
        width: '100vw',
        display: 'grid',
        placeItems: 'center',
      }}
    >
      <div
        className="animate-pulse"
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '0.5rem',
          alignItems: 'center',
        }}
      >
        <div className="animate-pulse">
          <ConstructableLogo />
        </div>
        <div className="text-sm text-muted-foreground">Constructable</div>
      </div>
    </div>
  );
};

export const ErrorBoundary = DefaultErrorBoundary;

function App() {
  const navigate = useNavigate();
  useEffect(() => {
    const setupListener = async () => {
      const listener = await CapacitorApp.addListener(
        'appUrlOpen',
        (event: URLOpenListenerEvent) => {
          try {
            const url = new URL(event.url);
            if (!url.hostname.endsWith('app.constructable.ai')) {
              console.log('Ignoring non-deep link:', url.href);
              return;
            }

            const path = url.pathname + url.search;
            console.log(`Navigating to: ${path}`);
            navigate(path);
          } catch (err) {
            console.error('Failed to handle deep link:', err);
          }
        }
      );
      return listener;
    };

    const listener = setupListener();
    return () => {
      listener.then((x) => x.remove());
    };
  }, [navigate]);

  return (
    <Suspense fallback={null}>
      <Outlet />
    </Suspense>
  );
}

export default App;
